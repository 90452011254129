<template>
  <RegisterForm />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import RegisterForm from '@/components/account/RegisterForm.vue'

@Options({
  components: { RegisterForm },
  directives: { maska },
})
export default class Register extends Vue {}
</script>
